import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";

import { IMapsPoint } from "../../3-organisms/LocationFinder/LocationFinder";

import * as css from "./AddressList.module.scss";

export interface AddressListProps {
  distanceLabel?: string;
  // distance in km
  city: string;
  consultationDays?: Array<string>;
  distance: number;
  isActive: boolean;
  isHighlighted?: boolean;
  id?: string;
  name: string;
  point?: IMapsPoint;
  street: string;
  onMouseEnter: (e: MouseEvent) => void;
  onMouseLeave: (e: MouseEvent) => void;
  onClick: (e: MouseEvent) => void;
}

interface FormatDistanceProps {
  distance: AddressListProps["distance"];
}
const FormatDistance: React.FC<FormatDistanceProps> = ({
  distance,
}: FormatDistanceProps) => {
  let decimals = 0; // fallback for smaller than 1 (use m then)
  if (distance >= 100) decimals = 0;
  else if (distance >= 10) decimals = 1;
  else if (distance >= 1) decimals = 2;

  // >= 100 => return distance in km with 1 decimal
  if (distance >= 1) {
    return (
      <>
        {new Intl.NumberFormat("de-DE", {
          style: "decimal",
          maximumFractionDigits: decimals,
        }).format(distance)}
        &nbsp;km
      </>
    );
  }

  // < 1 => return meters without decimals
  return (
    <>
      {new Intl.NumberFormat("de-DE", {
        style: "decimal",
        maximumFractionDigits: 0,
      }).format(distance * 1000)}
      &nbsp;m
    </>
  );
};

const getDate = dateString => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  return new Intl.DateTimeFormat("de-DE", options).format(date);
};

const getRouteUrl = (
  name: AddressListProps["name"],
  street: AddressListProps["street"],
  city: AddressListProps["city"]
) => {
  return encodeURI(
    `https://www.google.de/maps/search/?de=language&api=1&query=${name},${street},${city}`
  );
};

export const AddressList: React.FC<AddressListProps> = ({
  consultationDays = [],
  distanceLabel = "Entfernung",
  distance,
  isActive,
  isHighlighted = false,
  id,
  name,
  street,
  city,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: // point,
AddressListProps) => {
  const hasConsultationDays =
    Array.isArray(consultationDays) && consultationDays.length > 0;
  const hasDistance = typeof distance === "number";

  return (
    <div
      id={id}
      className={classNames(getClassName(css, "AddressList"), {
        [getClassName(css, "AddressList--active") as string]: isActive,
        [getClassName(css, "AddressList--highlight") as string]: isHighlighted,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {hasDistance ? (
        <div className={getClassName(css, "AddressList__header")}>
          {distanceLabel}
          <span className={getClassName(css, "AddressList__distance")}>
            <FormatDistance distance={distance} />
          </span>
        </div>
      ) : null}
      <p className={getClassName(css, "AddressList__name")}>{name}</p>
      <p className={getClassName(css, "AddressList__info")}>
        {street}
        <br />
        {city}
      </p>

      {hasConsultationDays ? (
        <>
          <span
            className={getClassName(
              css,
              "AddressList__consultation-days-headline"
            )}
          >
            Beratungstage
          </span>
          <ul
            className={getClassName(css, "AddressList__consultation-days-list")}
          >
            {consultationDays.map(date => (
              <li
                key={date}
                className={getClassName(
                  css,
                  "AddressList__consultation-days-list-item"
                )}
              >
                {getDate(date)}
              </li>
            ))}
          </ul>
        </>
      ) : null}

      <a
        className={getClassName(css, "AddressList__link")}
        href={getRouteUrl(name, street, city)}
        target="_blank"
        rel="noreferrer noopener"
      >
        Anfahrt
      </a>
    </div>
  );
};

export default AddressList;
