// TODO: add Typescript Typings
import React from "react";

import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Helmet, HelmetProps } from "react-helmet-async";
import { default as _get } from "lodash/get";

import { getClassName } from "../components/0-electrons/css";
import LocationFinder from "../components/3-organisms/LocationFinder/LocationFinder";

import * as css from "./ContentfulLocationFinder.module.scss";

const ContentfulLocationFinder = ({ content, locations }) => {
  const image = _get(content, "image.image.gatsbyImageData", undefined);
  const imageTitle = _get(content, "image.title", undefined);
  const hasImage = typeof image !== "undefined";

  return (
    <section className={getClassName(css, "LocationFinder")}>
      <Helmet>
        <link rel="preconnect" href="https://maps.googleapis.com" />
        <link rel="preconnect" href="https://maps.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
      </Helmet>

      {hasImage ? (
        <GatsbyImage
          className={getClassName(css, "LocationFinder__img")}
          image={image}
          alt={imageTitle}
        />
      ) : null}

      <LocationFinder
        apiKey={process.env.MAPS_API_KEY as string}
        locations={locations}
        headline="Apotheken-Finder"
        intro="Finde eine Partner-Apotheke in deiner Nähe und lass dich beraten."
        facade={
          <StaticImage
            src="../images/maps/placeholder.png"
            width={1280}
            quality={90}
            alt="Google Maps"
          />
        }
      />
    </section>
  );
};

export default ContentfulLocationFinder;

export const query = graphql`
  fragment FragmentContentfulLocationFinder on ContentfulComponentLocationFinder {
    # base
    internal {
      type
    }
    idSlug
    # rest
    image {
      title
      image {
        gatsbyImageData(placeholder: DOMINANT_COLOR, width: 1110, quality: 85)
      }
    }
  }
`;
