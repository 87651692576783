import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import Icon from "../../0-electrons/Icon/Icon";

import * as css from "./Marker.module.scss";

export interface MarkerProps {
  /** If the marker is active */
  isActive?: boolean;
  /** If the marker should be highlighted */
  isHighlighted?: boolean;
  /** If the campaign marker should be used */
  isCampaign?: boolean;
  /** onClick Function */
  onClick: (e: MouseEvent) => void;
  /** Latitude of the marker */
  lat?: number;
  /** Longitude of the marker */
  lng?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Marker: React.FC<MarkerProps> = ({
  isActive,
  isHighlighted,
  isCampaign = false,
  onClick,
  lat,
  lng,
}: MarkerProps) => (
  <Icon
    className={classNames(getClassName(css, "Marker"), {
      [getClassName(css, "Marker--active") as string]: isActive,
      [getClassName(css, "Marker--highlight") as string]: isHighlighted,
    })}
    fluid
    type={isCampaign ? "locationCampaign" : "location"}
    color="primary"
    onClick={onClick}
  />
);

export interface MarkerClusterProps {
  /** If the marker is active/should be highlighted */
  // isActive?: boolean;
  /** Latitude of the marker */
  // lat?: number;
  /** Longitude of the marker */
  // lng?: number;
}

const MarkerCluster = ({
  count,
  onClick,
  // needed for the maps implementation
  lat,
  lng,
}) => {
  const size = count > 30 ? "l" : count >= 10 ? "m" : "s";
  const sizeClassName = css[`MarkerCluster--size-${size}`];
  const sizeClassNameExists = typeof sizeClassName === "string";

  return (
    <div
      className={classNames(css.MarkerCluster, {
        [sizeClassName]: sizeClassNameExists,
      })}
      onClick={onClick}
    >
      {count}
    </div>
  );
};

export default Marker;
export { Marker, MarkerCluster };
