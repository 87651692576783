import { default as _get } from "lodash/get";
import { default as _max } from "lodash/max";
import { default as _min } from "lodash/min";
import { default as _round } from "lodash/round";

import { fitBounds } from "google-map-react";

// @url https://github.com/google-map-react/google-map-react/blob/master/API.md#fitbounds-func
export const getBounds = (
  locations,
  mapElement,
  mapsApi,
  distance = 10000 // set 10 000 as fallback, if distance is not defined, will only be used if there is just one result
) => {
  if (!mapElement) {
    return {
      center: NaN,
      zoom: NaN,
      // newBounds
    };
  }

  const latValues = [];
  const lngValues = [];

  const bounds = { nw: {}, se: {} };
  if (locations.length > 1) {
    locations.forEach(location => {
      latValues.push(location.point.lat);
      lngValues.push(location.point.lng);
    });

    bounds.nw = {
      lat: _max(latValues),
      lng: _min(lngValues),
    };
    bounds.se = {
      lat: _min(latValues),
      lng: _max(lngValues),
    };
  } else if (locations.length === 1) {
    // create temp circle to get bounds as fallback (as there is only 1 result)
    const center = locations[0].point;
    // draw circle
    const circleForBounds = new mapsApi.maps.Circle({
      /*
       * For debugging: settings to display the circle. Remember to
       * comment out "circleForBounds.setMap(null);" as the circle will
       * be removed again
       **/
      // strokeColor: "#FF0000",
      // strokeOpacity: 0.8,
      // strokeWeight: 2,
      // fillColor: "#FF0000",
      // fillOpacity: 0.35,
      map: mapsApi.map,
      center: center,
      radius: distance * 1000,
    });

    // get bounds and convert for usage with fitBounds
    // function of google-map-react
    const circleBounds = circleForBounds.getBounds();
    // convert to needed nw and se coordinates
    // NE => NW
    // SW => SE
    const circleBoundsNwSe = {
      nw: {
        lat: circleBounds.getNorthEast().lat(),
        lng: circleBounds.getSouthWest().lng(),
      },
      se: {
        lat: circleBounds.getSouthWest().lat(),
        lng: circleBounds.getNorthEast().lng(),
      },
    };
    bounds.nw = circleBoundsNwSe.nw;
    bounds.se = circleBoundsNwSe.se;

    // remove temp circle from map
    circleForBounds.setMap(null);
  }

  const mapBoundingClientRect = mapElement.getBoundingClientRect();
  const size = {
    width: mapBoundingClientRect.width, // Map width in pixels
    height: mapBoundingClientRect.height, // Map height in pixels
  };

  const newBounds = fitBounds(bounds, size);

  return newBounds;
};

/**
 * Get QueryType: textInput (by User) or Suggestion of Maps API?
 */
export function getQueryType(query) {
  if (typeof _get(query, "placeId", null) === "string") return "suggestion";

  return "textInput";
}

/**
 * getHaversineDistance
 *
 * based on @url https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 * see @url https://en.wikipedia.org/wiki/Haversine_formula
 */
export function getHaversineDistance(point1, point2, roundDecimals = 2) {
  // const R = 3958.8; // Radius of the Earth in miles
  const R = 6371.071; // Radius of the Earth in kilometres
  const rlat1 = point1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = point2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (point2.lng - point1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );

  if (typeof roundDecimals === "number") {
    return _round(d, 2);
  }

  return d;
}
