// TODO: click on marker when map loaded with all markers
import React, { useState, useEffect, useRef } from "react";

import classNames from "classnames";
import useSupercluster from "use-supercluster";

import { default as _isEmpty } from "lodash/isEmpty";
import { default as _get } from "lodash/get";
import { default as _has } from "lodash/has";
// import { default as _min } from "lodash/min";
// import { default as _max } from "lodash/max";
// import { default as _round } from "lodash/round";

import { getClassName } from "../../0-electrons/css";
import { Map, MapProps } from "../../1-atoms/GoogleMaps/Map";
import { Marker, MarkerCluster } from "../../1-atoms/GoogleMaps/Marker";
import AddressList from "../../2-molecules/AddressList/AddressList";
import LocationFinderControls, {
  LocationFinderControlsProps,
} from "../../2-molecules/LocationFinderControls/LocationFinderControls";

import { getBounds, getQueryType, getHaversineDistance } from "./helper";

import * as css from "./LocationFinder.module.scss";

const defaultProps = {
  center: {
    /**
     * Center of Germany
     *
     * @url https://www.google.de/maps/place/Mittelpunkt+Deutschlands/@51.1633908,10.4455304,17z/data=!3m1!4b1!4m5!3m4!1s0x47a492777f2a1115:0x8b5ec99680bbc907!8m2!3d51.1633908!4d10.4477191
     */
    lat: 51.16354552494406,
    lng: 10.44784784435708,
  },
  zoom: 6,
};

const filterOptions = [
  {
    label: "Alle Apotheken",
    value: "default",
  },
  // {
  //   label: "mit #hautstark Sortiment",
  //   value: "flagHautstark",
  // },
];
export interface IMapPoint {
  /** Latitude of the item */
  lat: number;
  /** Longitude of the item */
  lng: number;
}
export interface LocationItemProps {
  /** id of the location */
  id: string;
  /** Name of the location */
  name: string;
  /** street including house number */
  street: string;
  /** Zip Code */
  zip: number | string;
  /** City */
  city: string;
  /** Map Point */
  point: IMapPoint;
  /** if it is active right now */
  isActive?: boolean;
}

export interface LocationFinderProps extends MapProps {
  headline: LocationFinderControlsProps["headline"];
  intro: LocationFinderControlsProps["children"];
  locations: Array<LocationItemProps>;
}

interface IMapsApi {
  map: any;
  maps: any;
}

const LocationFinder: React.FC<LocationFinderProps> = ({
  apiKey,
  facade,
  headline,
  intro,
  locations = [],
}: LocationFinderProps) => {
  /**
   * Refs
   */
  const mapRef = useRef(null);
  const listRef = useRef(null);

  /**
   * State handling
   */
  // maps api
  const [mapsApi, setMapsApi] = useState<IMapsApi | null>(null);
  const [zoom, setZoom] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [center, setCenter] = useState(null);

  // Mode for filtering locations that are displayed as results
  //   1. 'search': we've a search query, so we can filter based on this
  //   2. 'visible': only show visible markers as results
  //   3. null: default, don't show result list
  const [mode, setMode] = useState(null);

  // suggestions
  const suggestionsDefault = [];
  const [suggestions, setSuggestions] =
    useState<Array<string>>(suggestionsDefault);

  // query values
  const queryDefault = { text: "" };
  const [query, setQuery] = useState(queryDefault); // should have description, placeId
  const queryDistanceDefault = 5;
  const [queryDistance, setQueryDistance] =
    useState<number>(queryDistanceDefault);
  const hasQueryDistance = typeof queryDistance === "number";

  // TODO: TS Typings
  const [queryFilter, setQueryFilter] = useState(undefined);

  // TODO: add searchQuery typings
  const [searchQuery, setSearchQuery] = useState(null);
  const hasSearchQuery =
    _has(searchQuery, "center.lat") &&
    _has(searchQuery, "center.lng") &&
    hasQueryDistance;

  // Marker Handling
  const [markerActive, setMarkerActive] = useState(null);
  // const hasMarkerActive =
  //   typeof markerActive === "string" && markerActive.length;
  const [markerHover, setMarkerHover] = useState(null);

  const mapZoomAndPan = (zoom, point) => {
    const { lat, lng } = point;

    // mapsApi.map.setZoom(zoom);
    // mapsApi.map.panTo({ lat, lng });
    setZoom(zoom);
    setCenter({ lat, lng });
  };
  const zoomToMarker = location => {
    mapZoomAndPan(17, location.point);

    if (mode !== "search") {
      setMode("visible");
    }
  };

  // Controls/intro handling
  const showResults = mode === "visible" || mode === "search";

  // error handling
  const [error, setError] = useState(undefined);
  const resetError = () => setError(undefined);
  const hasError = !_isEmpty(error);

  // geo location
  const [geoLocation, setGeoLocation] = useState<IMapPoint | null>(null);
  const hasGeoLocation = _has(geoLocation, "lat") && _has(geoLocation, "lng");

  // Get Geo location
  useEffect(() => {
    if (
      _get(query, "text", null) !== queryDefault.text &&
      geoLocation === null &&
      navigator.geolocation
    ) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const geoCoordinates = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setGeoLocation(geoCoordinates);
        }
      );
    }
  }, []);

  // Search Query Suggestions
  useEffect(() => {
    if (mapsApi === null) return;
    // only get results when the query change is triggered
    // by textInput (and not a click on a suggestion)
    // if it is a suggestion, the user already found the right place
    const isSuggestionResult = getQueryType(query) === "suggestion";
    if (isSuggestionResult) return;

    const serviceAutocomplete = new mapsApi.maps.places.AutocompleteService(
      mapsApi.map
    );

    // see @url https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const request = {
      input: query.text,
      // radius: 50000, // TODO: use currently set distance
      // TODO: add locationBias
      location: hasSearchQuery
        ? new mapsApi.maps.LatLng(
            searchQuery.center.lat,
            searchQuery.center.lng
          )
        : hasGeoLocation
        ? new mapsApi.maps.LatLng(geoLocation.lat, geoLocation.lng)
        : new mapsApi.maps.LatLng(
            defaultProps.center.lat,
            defaultProps.center.lng
          ),
      // radius in meters to use for prediction biasing
      // center of this radius is location
      radius: (hasQueryDistance ? queryDistance : 10) * 1000,
      // start with string length of 3 for predictions
      offset: 3,
      // origin to use to calculate distance_meters
      origin: hasGeoLocation ? geoLocation : null,
      fields: ["geometry", "formatted_address", "distance_meters"],
    };

    serviceAutocomplete.getPlacePredictions(
      request,
      function (results, status) {
        if (
          status === mapsApi.maps.places.PlacesServiceStatus.OK &&
          results !== null
        ) {
          const resultsSortedByDistance = results.sort((a, b) => {
            const aDistance = a.hasOwnProperty("distance_meters")
              ? a.distance_meters
              : Infinity;
            const bDistance = b.hasOwnProperty("distance_meters")
              ? b.distance_meters
              : Infinity;

            if (aDistance < bDistance) return -1;
            if (aDistance > bDistance) return 1;

            return 0;
          });

          setSuggestions(resultsSortedByDistance.map(result => result));
        }
      }
    );
  }, [query]);

  // Scroll to Active Marker
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      showResults &&
      typeof listRef.current !== null
    ) {
      // TODO: use function to get ID
      const addressListId = `address-${markerActive}`;
      const addressListElement = document.getElementById(addressListId);

      if (addressListElement !== null) {
        const listRefRect = listRef.current.getBoundingClientRect(); // parent element
        const addressListElementRect =
          addressListElement.getBoundingClientRect(); // child element

        listRef.current.scrollTo({
          top:
            addressListElementRect.top -
            // also use current scrollTop position when an active marker
            // has been set already
            (listRefRect.top - listRef.current.scrollTop),
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [markerActive]);

  // update map when searchQuery changes
  useEffect(() => {
    const {
      center: updateCenter,
      zoom: updateZoom,
      // newBounds: bounds,
    } = getBounds(
      Array.isArray(locationsFiltered) ? locationsFiltered : [],
      mapRef?.current,
      mapsApi,
      hasQueryDistance ? queryDistance : queryDistanceDefault
    );
    mapZoomAndPan(updateZoom, updateCenter);
  }, [searchQuery]); // react to changes to filtered locations

  /**
   * Get Locations
   */
  const locationsWithState = locations.map(location => {
    return {
      ...location,
      isActive: location.id === markerActive,
      isHighlighted: location.id === markerHover,
    };
  });

  // filter when searchQuery is set
  const locationsFiltered = hasSearchQuery
    ? locationsWithState
        .map(location => {
          return {
            ...location,
            distance: getHaversineDistance(searchQuery.center, location.point),
          };
        })
        // use queryFilter
        .filter(location => {
          if (
            typeof queryFilter === "string" &&
            queryFilter !== "default" &&
            location[queryFilter] !== true
          ) {
            return false;
          }

          return true;
        })
        // filter first that sorting is easier (as there are less locations)
        // allow 10% more distance via distance * 1.1
        .filter(location => location.distance <= searchQuery.distance * 1.1)
        .sort((a, b) => {
          if (a.distance < b.distance) return -1;
          if (a.distance > b.distance) return 1;

          return 0;
        })
    : locationsWithState;

  const locationsWithClusterFormat = locationsFiltered
    // .slice(0, 100)
    .map(location => ({
      type: "Feature",
      properties: {
        cluster: false,
        location,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(location.point.lng),
          parseFloat(location.point.lat),
        ],
      },
    }));

  // useSupercluster retunrs clusters and
  // supercluster as properties
  const { clusters, supercluster } = useSupercluster({
    points: locationsWithClusterFormat,
    zoom,
    bounds,
    options: { radius: 80, maxZoom: 16 },
  });

  // get all markers that are visible and not a cluster
  const clustersVisible = clusters.filter(cluster => {
    const { cluster: isCluster } = cluster.properties;

    return !isCluster;
  });

  // if we use the visible cluster markers map back to locations format
  const resultList =
    mode === "visible"
      ? clustersVisible.map(cluster => cluster.properties.location)
      : locationsFiltered;

  return (
    // Outer container needed for Google Maps
    <div className={getClassName(css, "LocationFinder")}>
      <div
        className={classNames(getClassName(css, "LocationFinder__controls"), {
          [getClassName(css, "LocationFinder__controls--has-query")]:
            showResults,
        })}
      >
        <LocationFinderControls
          // distance props
          onChangeDistance={e => {
            const value = _get(e, "target.value", undefined);
            setQueryDistance(parseInt(value));
          }}
          distance={queryDistance}
          // distance props
          onChangeFilter={e => {
            const value = _get(e, "target.value", undefined);
            setQueryFilter(value);
          }}
          filter={queryFilter}
          filterOptions={filterOptions}
          // query props
          onChangeQuery={e => {
            // TODO: throttle/debounce
            const value = _get(e, "target.value", undefined);
            setQuery({ text: value }); // only set text here, should only be triggered when typing into the field, but not on suggestion clicks
          }}
          queryValue={_get(query, "text", undefined)}
          // suggestion props
          suggestions={getQueryType(query) === "textInput" ? suggestions : []}
          onClickSuggestion={suggestion => {
            // We need to set the map center and the current address.
            // Also we reset the suggestions that are no more suggestions
            // visible if there have been more than one

            const servicePlaces = new mapsApi.maps.places.PlacesService(
              mapsApi.map
            );

            // // see @url https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService.getDetails
            servicePlaces.getDetails(
              {
                placeId: suggestion.place_id,
                fields: ["geometry", "formatted_address"],
              },
              (placeResult, placeServiceStatus) => {
                if (
                  placeServiceStatus ===
                    mapsApi.maps.places.PlacesServiceStatus.OK &&
                  placeResult !== null
                ) {
                  const placeCenter = _has(placeResult, "geometry.location")
                    ? placeResult.geometry.location
                    : null;
                  const hasPlaceCenter = placeCenter !== null;

                  setQuery({
                    text: suggestion.description,
                    placeId: suggestion.place_id,
                    center: hasPlaceCenter
                      ? {
                          lat: placeCenter.lat(),
                          lng: placeCenter.lng(),
                        }
                      : undefined,
                  });
                }
              }
            );

            setSuggestions(
              suggestions.map(item => {
                return {
                  ...item,
                  active: item.place_id === suggestion.place_id,
                };
              })
            );
          }}
          // search props
          onSearch={() => {
            resetError();

            const hasQuery = !_isEmpty(query);

            if (!hasQuery || !hasQueryDistance) {
              setError("Bitte Suchbegriff und Distanz eingeben");
            }

            const searchQuery = {
              distance: queryDistance,
              center: query.center,
            };

            // TODO: set bounds according to outer locations
            setSearchQuery(searchQuery);
            setMode("search");
          }}
          // text props
          headline={showResults ? null : headline}
        >
          {showResults ? null : intro}
        </LocationFinderControls>

        {/* TODO: error styling */}
        {hasError ? (
          <div className={getClassName(css, "LocationFinder__notice")}>
            {error}
          </div>
        ) : null}

        {showResults ? (
          <div
            className={getClassName(css, "LocationFinder__list")}
            ref={listRef}
          >
            {resultList.length > 0 ? (
              resultList.map(location => (
                <AddressList
                  key={location.id}
                  // TODO: use function to get ID
                  id={`address-${location.id}`}
                  city={`${location.zip} ${location.city}`}
                  consultationDays={location.consultationDays}
                  distance={location.distance}
                  isActive={location.isActive}
                  isHighlighted={location.isHighlighted}
                  name={location.name}
                  point={location.point}
                  street={location.street}
                  onMouseEnter={e => setMarkerHover(location.id)}
                  onMouseLeave={e => setMarkerHover(null)}
                  onClick={() => {
                    zoomToMarker(location);
                    setMarkerActive(location.id);
                  }}
                />
              ))
            ) : (
              <div className={getClassName(css, "LocationFinder__notice")}>
                <strong>Keine Apotheken gefunden.</strong>
                <br />
                Nichts in Ihrer Nähe dabei? Keine Sorge. Erweitern Sie den
                Suchradius oder schauen Sie in ein paar Tagen noch einmal
                vorbei.
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={getClassName(css, "LocationFinder__map")} ref={mapRef}>
        <Map
          // defaults and props for API
          apiKey={apiKey}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onMapsApiLoaded={({ map, maps }) => {
            setMapsApi({ maps, map });
          }}
          // props for interacting with map
          // and set via search queries
          // center={
          //   boundsCenter || (hasSearchQuery ? searchQuery.center : undefined)
          // }
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
          zoom={zoom}
          center={center}
          facade={facade}
        >
          {clusters.map(cluster => {
            const [lng, lat] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
              location,
            } = cluster.properties;

            if (isCluster) {
              return (
                <MarkerCluster
                  count={pointCount}
                  key={cluster.id}
                  lat={lat}
                  lng={lng}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );

                    mapZoomAndPan(expansionZoom, { lat, lng });
                  }}
                />
              );
            }

            return (
              <Marker
                key={location.id}
                isActive={location.isActive}
                isCampaign={location.flagCampaign}
                isHighlighted={location.isHighlighted}
                lat={lat}
                lng={lng}
                onClick={e => {
                  e.stopPropagation();
                  setMarkerActive(location.id);
                  zoomToMarker(location);
                }}
              />
            );
          })}
        </Map>
      </div>
    </div>
  );
};

export default LocationFinder;
export { LocationFinder };
