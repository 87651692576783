import React from "react";

import { getClassName } from "../../0-electrons/css";
import classNames from "classnames";
import * as css from "./Select.module.scss";
import { Icon } from "../../0-electrons/Icon/Icon";

export interface SelectOptionProps {
  /** Label like 5km */
  label: string;
  /** Value like 5 (in km) */
  value: string | number;
}

export interface SelectProps {
  options: Array<SelectOptionProps>;
  className?: string;
  /** onChange callback */
  onChange?: (event: React.ChangeEvent) => void;
  value?: number | string;
}

export const Select: React.FC<SelectProps> = ({
  options,
  className,
  onChange,
  value,
}: SelectProps) => {
  const hasOptions = Array.isArray(options) && options.length > 0;
  const valueWithFallback =
    typeof value !== "string" && typeof value !== "number" && hasOptions
      ? options[0].value
      : null;

  return (
    <div
      className={classNames(getClassName(css, "Select"), {
        [className as string]:
          typeof className === "string" && className.length > 0,
      })}
    >
      <select
        className={classNames(getClassName(css, `Select__field`))}
        onChange={onChange}
      >
        {options.map(option => (
          <option
            value={option.value}
            key={option.value}
            selected={option.value === valueWithFallback}
          >
            {option.label}
          </option>
        ))}
      </select>
      <Icon
        type="directionDown"
        color="primary"
        className={getClassName(css, "Select__icon")}
      />
    </div>
  );
};

export default Select;
