import React from "react";

import { getClassName } from "../../0-electrons/css";
import classNames from "classnames";
import * as css from "./Input.module.scss";

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  borderless?: boolean;
  textColor?: "default" | "grey";
}

export const Input: React.FC<InputProps> = ({
  className,
  borderless,
  textColor = "default",
  ...props
}: InputProps) => {
  return (
    <input
      className={classNames(
        getClassName(css, "Input"),
        getClassName(css, `Input__field`),
        getClassName(css, `input-${textColor}`),
        borderless && (getClassName(css, "Input--borderless") as string),
        className
      )}
      {...props}
    />
  );
};

Input.defaultProps = {
  type: "text",
};

export default Input;
