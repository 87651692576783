// extracted by mini-css-extract-plugin
export var AddressList = "AddressList-module--AddressList--DM6hw";
export var AddressListActive = "AddressList-module--AddressList--active--nnKCa";
export var AddressListHighlight = "AddressList-module--AddressList--highlight--gRPxj";
export var AddressList__consultationDaysHeadline = "AddressList-module--AddressList__consultation-days-headline--lqN4w";
export var AddressList__consultationDaysList = "AddressList-module--AddressList__consultation-days-list--Svjqz";
export var AddressList__consultationDaysListItem = "AddressList-module--AddressList__consultation-days-list-item--Hf7jh";
export var AddressList__distance = "AddressList-module--AddressList__distance--XATq+";
export var AddressList__header = "AddressList-module--AddressList__header--jQL8U";
export var AddressList__info = "AddressList-module--AddressList__info--zb2aL";
export var AddressList__link = "AddressList-module--AddressList__link--A96wU";
export var AddressList__name = "AddressList-module--AddressList__name--1TECj";