// TODO: fix typings
import React from "react";

import { default as _isEmpty } from "lodash/isEmpty";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import Select, {
  SelectProps,
  SelectOptionProps,
} from "../../1-atoms/Select/Select";
import Input, { InputProps } from "../../1-atoms/Input/Input";

import Button from "../../1-atoms/Button/Button";

import * as css from "./LocationFinderControls.module.scss";

export interface LocationFinderControlsProps {
  /* The Pharmacy Finder Children */
  children?: React.ReactNode;
  /** className to add */
  className?: string;
  /* The Pharmacy Finder Headline */
  headline?: React.ReactNode;
  /**
   * Query Distance Props
   */
  /** current distance value */
  distance?: number;
  /* Select options */
  distanceOptions?: Array<SelectOptionProps>;
  /** distance onChange */
  onChangeDistance?: SelectProps["onChange"];
  /**
   * Filter Props
   */
  /** current filter value */
  filter?: string;
  /* Select options */
  filterOptions?: Array<SelectOptionProps>;
  /** distance onChange */
  onChangeFilter?: SelectProps["onChange"];
  /**
   * Query Input Props
   */
  /** Input placeholder */
  queryPlaceholder?: string;
  /** The queries value */
  queryValue?: string;
  /** Input onChange */
  onChangeQuery?: InputProps["onChange"];
  /**
   * Suggestions
   */
  suggestions?: Array<string>;
  onClickSuggestion?: (value: string) => void;
  /**
   * Search props
   */
  /** onSearch callback when search is triggered */
  onSearch?: () => void;
}

export const defaultOptions: Array<SelectOptionProps> = [
  {
    label: "10km",
    value: 10,
  },
  {
    label: "25km",
    value: 25,
  },
  {
    label: "50km",
    value: 50,
  },
  {
    label: "100km",
    value: 100,
  },
];

// TODO: normalize suggestion function: formatted_address -> address

export const LocationFinderControls: React.FC<LocationFinderControlsProps> = ({
  children,
  className,
  headline,
  /** Query Distance Options */
  distance,
  distanceOptions = defaultOptions,
  onChangeDistance,
  /** Filter Options */
  filter,
  filterOptions = [],
  onChangeFilter,
  /** Query Input Options */
  queryPlaceholder = "Stadt, PLZ",
  queryValue,
  onChangeQuery,
  /** Suggestions */
  suggestions = [],
  onClickSuggestion,
  /** onSearch */
  onSearch,
}: LocationFinderControlsProps) => {
  const hasQueryValue = !_isEmpty(queryValue);
  const hasDistance = typeof distance === "number";
  const hasSuggestions =
    // only show suggestions when we have some
    Array.isArray(suggestions) && suggestions.length > 0;
  /* if there are no filter options, don't show them and adjust CSS for either distance or filter to add space for the cta button */
  const hasFilterOptions =
    Array.isArray(filterOptions) && filterOptions.length > 1;

  return (
    <section
      className={classNames(getClassName(css, "LocationFinderControls"), {
        [className as string]:
          typeof className === "string" && className.length > 0,
      })}
    >
      <div className={getClassName(css, "LocationFinderControls__intro")}>
        {!_isEmpty(headline) ? (
          <TypographyHeadline
            color="grey"
            size="h2"
            className={getClassName(css, "LocationFinderControls__headline")}
            disableSpacing
          >
            {headline}
          </TypographyHeadline>
        ) : null}

        {!_isEmpty(children) ? (
          <TypographyCopy
            color="grey"
            size="default"
            className={getClassName(css, "LocationFinderControls__copy")}
          >
            {children}
          </TypographyCopy>
        ) : null}
      </div>

      <div className={getClassName(css, "LocationFinderControls__controls")}>
        <Input
          className={getClassName(css, "LocationFinderControls__input")}
          placeholder={queryPlaceholder}
          onChange={onChangeQuery}
          value={queryValue}
        />

        {hasSuggestions ? (
          <nav
            className={getClassName(css, "LocationFinderControls__suggestions")}
          >
            <div
              className={getClassName(
                css,
                "LocationFinderControls__suggestions-list"
              )}
            >
              {suggestions.map((suggestion, index) => (
                <button
                  key={`suggestion-${index}`}
                  type="button"
                  className={getClassName(
                    css,
                    "LocationFinderControls__suggestions-item"
                  )}
                  onClick={() => {
                    if (typeof onClickSuggestion === "function") {
                      onClickSuggestion(suggestion);
                    }
                  }}
                >
                  {suggestion.description}
                </button>
              ))}
            </div>
          </nav>
        ) : null}

        <Select
          className={classNames(
            getClassName(css, "LocationFinderControls__select"),
            {
              [getClassName(
                css,
                "LocationFinderControls__select--margin-right"
              ) as string]: !hasFilterOptions,
            }
          )}
          options={Array.isArray(distanceOptions) ? distanceOptions : []}
          onChange={onChangeDistance}
          value={distance}
        />

        {hasFilterOptions ? (
          <Select
            className={classNames(
              getClassName(css, "LocationFinderControls__select"),
              {
                [getClassName(
                  css,
                  "LocationFinderControls__select--margin-right"
                ) as string]: hasFilterOptions,
              }
            )}
            options={Array.isArray(filterOptions) ? filterOptions : []}
            onChange={onChangeFilter}
            value={filter}
          />
        ) : null}

        <Button
          className={classNames(
            getClassName(css, "LocationFinderControls__button")
          )}
          tag="button"
          type="primary"
          iconOnly
          onClick={onSearch}
          disabled={!hasQueryValue || !hasDistance}
          // don't delete, needed for tracking via GTM
          // see @url https://jira.odt.net/browse/ODTCCYAN1-442
          data-gtm-pharmacy-search
        />
      </div>
    </section>
  );
};

export default LocationFinderControls;
