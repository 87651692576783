import React, { useEffect, useState } from "react";

import GoogleMapReact from "google-map-react";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";

import * as css from "./Map.module.scss";
export interface MapProps {
  /** Maps API Key */
  apiKey: string;
  /** The maps children: Should be the markers  */
  children: React.ReactNode;
  /** onChange: Function to trigger when map changes */
  onChange: (key: string) => void;
  /** onMarkerClick: Function to trigger when marker gets clicked. Passes the react key as argument */
  onMarkerClick?: (key: string) => void;
  /** Function callback when the maps api has loaded, passes object with map and maps as properties */
  onMapsApiLoaded?: GoogleMapReact.Props["onGoogleApiLoaded"];
  /**
   * GoogleMapReactProps
   */
  defaultCenter?: GoogleMapReact.Props["defaultCenter"];
  defaultZoom?: GoogleMapReact.Props["defaultZoom"];
  center?: GoogleMapReact.Props["center"];
  zoom?: GoogleMapReact.Props["zoom"];
  /** Facade */
  facade?: React.ReactNode | false;
}

export const defaultProps = {
  center: {
    // Stuttgart oddity GmbH
    lat: 48.77319806057185,
    lng: 9.175417182883791,
  },
  zoom: 11,
};

//greyScale style for the map
const mapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dddddd",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#dddddd",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#aaaaaa",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#aaaaaa",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

const Map: React.FC<MapProps> = ({
  apiKey,
  children,
  onChange,
  onMarkerClick,
  onMapsApiLoaded,
  /**
   * GoogleMapReactProps
   */
  defaultCenter,
  defaultZoom,
  center,
  zoom,
  /** Facade */
  facade = false,
}: MapProps) => {
  // Initialize facade setup to load Google Maps only after first interaction
  // map will only be loaded when using the facade when loadMap is set
  const [disableFacade, setDisableFacade] = useState(false);
  const hasFacade = facade !== false;

  useEffect(() => {
    if (hasFacade && typeof window !== "undefined") {
      // function to clean up events
      const removeEvents = () => {
        document.removeEventListener("click", triggerMapLoad);
        window.removeEventListener("scroll", triggerMapLoad);
        window.removeEventListener("mousemove", triggerMapLoad);
      };
      // trigger the map load
      const triggerMapLoad = () => {
        removeEvents();
        setDisableFacade(true);
      };
      const eventOptions = {
        once: true,
      };

      // add event listeners to capture first interaction
      window.addEventListener("scroll", triggerMapLoad, eventOptions);
      window.addEventListener("mousemove", triggerMapLoad, eventOptions);
      document.addEventListener("click", triggerMapLoad, eventOptions);
    }
  }, []);

  return !hasFacade || disableFacade === true ? (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: apiKey,
        libraries: ["places"],
      }}
      defaultCenter={defaultCenter || defaultProps.center}
      defaultZoom={defaultZoom || defaultProps.zoom}
      options={{ styles: mapStyles }}
      onChildClick={onMarkerClick}
      onChange={onChange}
      onGoogleApiLoaded={onMapsApiLoaded}
      yesIWantToUseGoogleMapApiInternals
      center={center}
      zoom={zoom}
    >
      {children}
    </GoogleMapReact>
  ) : (
    <Clone
      className={getClassName(css, "Map__facade")}
      node={facade as React.ReactElement}
    />
  );
};

export default Map;
export { Map };
